<template>
  <div class="main-view">
    <b-field label="Integration Name" class="column is-half">
        <b-input
          expanded
          v-model="name"
          :disabled=loading
          placeholder="Name"
          required
          minlength="2"
          maxlength="100"
          icon="application-export"
        >
        </b-input>
    </b-field>
    <b-button
        :loading=loading
        :disabled="name == null || name.length <= 2 || name.length >= 100"
        type="submit"
        label='Create'
        icon-left="mdi mdi-plus-box"
        aria-label="Create Integration"
        class="button is-primary"
        @click="createIntegration()"
    >
    </b-button>
    <button
        aria-label="Cancel"
        type="button"
        class="button is-light"
        @click="cancel">
        <i class="mdi mdi-cancel"/>Cancel</button>
  </div>
</template>
<script>

export default {
  name: 'CreateIntegration',
  computed: {
    tenant() {
      return this.$route.params.tenant;
    },
  },
  data() {
    return {
      loading: false,
      name: null,
      dataService: null,
    };
  },
  created() {
    this.dataService = this.$dataService(this.tenant);
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    createIntegration() {
      this.loading = true;
      this.dataService.integrations.create({ name: this.name })
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.$buefy.dialog.prompt({
              title: 'Integration Created',
              message: `The integration has been created. The client secret will
            only be displayed now and <b>it cannot be seen again later</b>.
              <br><br> Client secret:`,
              confirmText: 'I have copied it!',
              type: 'is-warning',
              hasIcon: true,
              inputAttrs: {
                type: 'text',
                value: res.data.clientSecret,
                disabled: true,
              },
              onConfirm: () => {
                this.$buefy.toast.open('Integration created!');
                this.$router.push({ name: 'integrationstab' });
              },
            });
          } else {
            this.$buefy.toast.open('Unable to create integration');
          }
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: e.response.data.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'alert-circle-outline',
            iconPack: 'mdi',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
